import { Faq, FaqCategory } from './types';

export const faqData: Faq[] = [
	{
		question: 'Why is there an admin fee?',
		answer: `Landlords may charge an admin fee to cover all the administrative costs associated with the lease including the cost of drafting the lease agreement as this may require a lawyer's input as well as the cost of any credit verification which is conducted by the landlord.`,
		categories: [FaqCategory.Payments],
	},
	{
		question: 'What is a key deposit?',
		answer:
			'A key deposit may refer to a fee which is paid to a landlord as security in a lease on a rental property. It may refer specifically to a specific type of deposit which shall be utilised solely for the cost of a replacement keys and locks to the rented premises. Landlords may request tenants to pay a deposit for various reasons including as security for any damage the tenant may cause to the property, or the replacement of keys and locks to the rented premises. Be sure to carefully read through your lease with regards to any reference to deposits.',
		categories: [FaqCategory.Deposits],
	},
	{
		question: 'Am I forced to take a 12 months lease?',
		answer:
			'A lease agreement is a contract between a tenant and a landlord and as with all contracts the terms may be negotiated between the two parties. Although most landlords may by default provide lease agreements with a 12-month term, you have the right to ask a landlord whether they would be open to signing a shorter-term or month-to-month lease agreement. Please note that different jurisdictions regulate short term leases differently. Certain countries may attach different conditions or taxes to short-term leases which may be payable by either landlord or tenant. Be sure to check which jurisdiction regulates your lease and, if necessary, consult a legal profession for advice regarding the term of your lease.',
		categories: [FaqCategory.Leases],
	},
	{
		question:
			'What is the minimum lease that I can take (might be coming for exams only for 3 months)?',
		answer:
			'There is no minimum lease term. However, any shortened lease term will have to be agreed with the landlord as most lease agreements have a default duration of 10-12 months or more. Be sure to get the agreed duration of your lease in writing.',
		categories: [FaqCategory.Leases],
	},
	{
		question: 'Who can I speak to about my lease contract except my parents and the landlord?',
		answer:
			'As a first step you may always ask a lawyer. We know lawyers are expensive, however many lawyers offer the first consultation free and may help guide you to some reliable resources for the jurisdiction of your lease. Depending on which country you are looking to rent in, always search for a rental housing tribunal or ombudsman. In South Africa each province has a Rental Housing Tribunal whose duties include giving advice and providing education to tenants about their rights and obligations.',
		categories: [FaqCategory.Leases],
	},
	{
		question:
			'What if the lease rental per month is different to what was initially verbally agreed upon? ',
		answer:
			'A verbal agreement is equally as valid as a written one and landlords therefore cannot unilaterally change any of the terms that were agreed upon even if it was agreed verbally. However, these verbal agreements can be difficult to prove if you do not have any supporting written evidence of the agreed terms. Refer to your correspondence with the landlord or their agent where the verbal agreement for the rental amount was stated. This should be reflected in the written lease and any changes would need to be agreed upon between both parties.',
		categories: [FaqCategory.Leases, FaqCategory.Deposits, FaqCategory.Payments],
	},
	{
		question:
			'Should we ask for an amendment of the lease if we make arrangements to pay the deposit over a few months rather than upfront?',
		answer:
			'It is always good practice to have any agreement recorded in writing. Leases often have a non-variation clause meaning that you may not vary the lease unless agreed to in writing between the parties. For this reason, we advise it is best that you have the clauses relating to the deposit amended upon signing the lease or an addendum to the lease recording the changes in these clauses signed by both parties.',
		categories: [FaqCategory.Leases, FaqCategory.Deposits, FaqCategory.Payments],
	},
	{
		question: 'When is a good time to pay, to avoid getting defrauded or robbed?',
		answer:
			'If this question refers to the payment of a deposit then you should ensure that such deposit is held in a bank account in which it accrues interest (preferably not your landlord’s personal bank account). This will provide you with an added layer of protection of your deposit. It may be that your landlord requires payment of the deposit to secure the property prior to providing you with a lease. We would advise that you ensure that the landlord agrees in writing that upon payment of the refundable deposit that you shall receive a copy of the lease agreement. With regard to paying rent then this is required as per the terms of your lease. For example, rent is often due on the first of the month for occupation of the premises in the upcoming month. To avoid fraud, always ensure that you are paying into the correct bank account. Verify this by checking the account details on the lease agreement or requesting confirmation from the agent and/or landlord.',
		categories: [FaqCategory.Payments],
	},
	{
		question:
			'What is the legal notice period that I can serve before moving out and cancelling my lease agreement?',
		answer:
			'In certain countries, there may be allowances in law for a tenant to provide the landlord with less notice than that of the lease agreement. For example, in South Africa in terms of the Consumer Protection Act, a tenant may provide a landlord with 20 business days (this excludes Saturdays, Sundays and national public holidays) notice of cancellation of the lease agreement. However, your lease agreement should stipulate an agreed notice period for cancelling it. Always carefully read through the notice period clause. Thereafter, seek the advice of a legal professional to see if, depending on the jurisdiction of the lease, the agreed notice period is compliant with the law.',
		categories: [FaqCategory.Leases],
	},
	{
		question: 'What recourse do I have if the landlord wants to kick me out?',
		answer:
			'The law surrounding evictions and what a landlord may and may not do differs from country to country. Generally, a landlord must provide you with adequate notice that they want you to vacate their property. In South Africa, a landlord is unable to remove someone from their property unless obtaining a court order. If you have received a lawful notice from your landlord, as a first step you need to engage your landlord to understand why they have given you that notice, if the notice does not already contain such information,  and if there is any way to negotiate you staying until the end of your lease term. There are always laws guiding the termination of a lease agreement. If you are feeling uncertain or that your landlord may be acting illegally, always search for a rental housing tribunal or ombudsman in your area. In South Africa each province has a Rental Housing Tribunal whose services are free and will help you settle any dispute you may have with your landlord.',
		categories: [FaqCategory.Leases],
	},
	{
		question:
			'How many months notice should a landlord give me if they want me to move out? Is this always the same as the notice period I have to serve should I want to move out?',
		answer:
			'The lease agreement will express the notice period required for both the landlord and the tenant. The Consumer Protection Act permits the tenant to give a 20 business day notice to cancel a residential lease irregardless of what is stated in the lease.',
		categories: [FaqCategory.Leases],
	},
	{
		question: 'Can I pay my rent in cash?',
		answer:
			'This will depend on whether you and your landlord have agreed to the method of payment. The lease agreement should state the agreed method of payment. You may make payment in cash, however, it is best that you have it recorded that this is agreed between yourself and the landlord. Also, record that the money was received by the landlord or their agent.',
		categories: [FaqCategory.Payments],
	},
	{
		question:
			'Can I lose my part of the deposit alone if I live in a digs renting with other students?',
		answer:
			'A deposit is paid by the tenant as security and should be refunded at the termination of the lease. However, there can be a variety of reasons why a deposit may not be refunded in full or at all. If you and your roommates contribute to the deposit then you should all be entitled to the portion of your contribution. However, this will be dependent on whether all of the students have signed as tenants and therefore have rights to their portion of the deposit in accordance with the lease or if only a single student has signed as a tenant and the other students’ rights to their portion of the deposit is only agreed between each other.',
		categories: [FaqCategory.Deposits],
	},
	{
		question: 'Can my landlord kick me out early?',
		answer:
			'Generally, a landlord must provide you with adequate notice that they want you to vacate their property. If a landlord wishes to evict their tenant then there are strict legal procedures that must be adhered to. For example, in South Africa, a landlord will need to proceed to court for an order evicting their tenant.',
		categories: [FaqCategory.Leases],
	},
	{
		question: 'How can I search for accommodation?',
		answer:
			'Easily search by location, price and more at <a href="https://www.digsconnect.com/">https://www.digsconnect.com/</a>. Each listing provides you with up-to-date info about the property and the area nearby, including amenities, highlights, bills included, transport map, and more. With DigsConnect, you can rest assured you’ll be speaking with trusted landlords on a safe and secure platform.',
		categories: [],
	},
	{
		question: 'How can I search for accommodation near my institution?',
		answer:
			'DigsConnect is proud to be the preferred accommodation partner for universities and educational institutions all over the world. Simply head to <a href="https://www.digsconnect.com/digs-search">https://www.digsconnect.com/digs-search</a> and search by your campus name or location. Filter by NSFAS accreditation, price and more! We have thousands of properties listed on our platform around the world, and we have no doubt we will help you find your dream home in close proximity or even walking distance to your campus.',
		categories: [],
	},
	{
		question: 'How does the booking process work?',
		answer:
			'The booking process has been made in order to simplify the entire process of finding a new home. When you fill out a short booking form with some key information, you will be sending the landlord all the details needed to see if you are a right fit for the listing, as well as any questions you may have about the property. Once the landlord accepts your booking, they will be in touch to seal the deal!',
		categories: [],
	},
	{
		question: 'How safe is your platform?',
		answer: `Safety is our number one priority! As the top student accommodation marketplace in the country and a woman-led company, we take safety very seriously — for both our landlords and our tenants. This is to ensure everyone’s peace of mind and to keep things running as smoothly as possible. To this end, we:
      </br>
      </br>
      <ul>
        <li>Verify cell phone numbers</li>
        <li>Verify email addresses</li>
        <li>Moderate landlords and ensure their listings are in line with our Terms and Conditions</li>
      </ul>
      </br>
      To protect your privacy we encourage you to chat directly through the Digs Chat feature and avoid sharing any personal information before verifying the identity of the person you are chatting with.
      `,
		categories: [],
	},
	{
		question: 'How quickly do landlords reply?',
		answer:
			'Landlords are given 48 hours to reply to your enquiry. As soon as a landlord replies, you should receive an email notification asking you to log into your inbox, reply to your landlord and continue with the booking process. If the enquiry expires for whatever reason, you can easily rebook on the chat or find similar listings to enquire on. You can access your inbox here: <a href="https://app.digsconnect.com/#/tenant/inbox">https://app.digsconnect.com/#/tenant/inbox</a>',
		categories: [],
	},
	{
		question: 'Why should I list my accommodation on DigsConnect?',
		answer:
			'Finding a tenant has never been easier with DigsConnect! With accommodation partnerships with universities and educational institutions all over the world, there are thousands of tenants looking for accommodation just like yours every hour of every day. When you receive a new enquiry from a potential tenant, you will receive all the information you need to make the right decision for your property, such as who will be financing the rent, what educational institution they are a part of (if applicable), whether they are a student or working professional, verified contact information, and more. DigsConnect has had more than 70,000 landlord and tenant connections to date, generating millions in lease value for landlords. You only pay a fee if you successfully get a tenant, meaning you can last as many properties as you’d like, free of charge! List your property in under 10 minutes today: <a href="https://digsconnect.com/create-listing">https://digsconnect.com/create-listing</a>',
		categories: [],
	},
	{
		question: 'How can I get more people to view and enquire on my listing?',
		answer: `We have several tips for optimising your listing to get it seen by more eyes on DigsConnect. </br>
    First up, be sure to have a nice, polished listing with as much description as possible and good, quality images!  Be sure to include details about your amenities and highlights of your space. Having too simple of a description could put you at a disadvantage, as one missing detail could have been the make or break in a tenant’s decision to enquire. Also, adding quality images really sets the scene for your potential applicants and easily allows them to picture themselves in your property. Update your listing here: <a href="https://app.digsconnect.com/#/account">https://app.digsconnect.com/#/account</a> </br>
    Another way to keep your listing front and center is by keeping up with your enquiries. It is important that you respond to pending enquiries on your listings and retain a good enquiry score. The quicker you reply, the higher your ranking score will be. A high ranking score means your listing will have a higher ranking and more users will see your listing when searching for properties in your area. Respond to any pending enquiries here: <a href="https://app.digsconnect.com/#/inbox">https://app.digsconnect.com/#/inbox</a>`,
		categories: [],
	},
	{
		question: 'How much do I have to pay to have my property listed on DigsConnect?',
		answer: `Upon confirmation of tenancy the landlord will be charged a fixed finder's fee of R800 for leases shorter than 6 months, and R1550 for leases of 6 months or longer per tenant. Rates are exclusive of VAT.`,
		categories: [],
	},
	{
		question: 'How many properties can I have listed at once?',
		answer:
			'Since we don’t have a listing fee with DigsConnect, you can list as many properties as you like on our secure platform.',
		categories: [],
	},
	{
		question: 'How do I speak to potential tenants?',
		answer:
			'We encourage you to speak to potential tenants via our safe and trusted Digs Chat inbox. Be sure to reply to enquiries within 48 hours or risk getting your listing deactivated after 5 expired enquiries.',
		categories: [],
	},
];
