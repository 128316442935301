import React, { useState, useEffect } from 'react';

import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import Main from 'components/common/main';
import Footer from 'components/common/footer';
import Header from 'components/common/header';
import { PageMeta } from 'constants/page_meta';
import theme from 'config/theme';
import { imgixMsdfUrl } from 'config/imgix';
import { BREAKPOINT_TABLET_PX } from 'constants/breakPoints';
import Input from 'lib/Input';
import { ReactComponent as SearchSvg } from 'assets/images/icons/search-outline.svg';
import { Divider } from 'lib/Divider';

import { faqData } from './faqData';
import CollapsibleSection from './CollapsibleSection';
import { FaqCategory } from './types';
import { Faq } from 'components/landing/faqs/types';

const categories = [
	{
		label: 'Deposits',
		category: FaqCategory.Deposits,
	},
	{
		label: 'Leases',
		category: FaqCategory.Leases,
	},
	{
		label: 'Payments',
		category: FaqCategory.Payments,
	},
];

const { colors, fontWeights } = theme;

const meta = PageMeta.getStatic('faq');

const width =
	window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

const Faqs = () => {
	const [search, setSearch] = useState('');
	const [faqs, setFaqs] = useState(faqData);
	const [category, setCategory] = useState('');
	const [expanded, setExpanded] = useState(false);

	useEffect(() => {
		const terms = search.toLowerCase().split(' ');
		setFaqs(
			faqData
				.filter(item => {
					if (!terms.filter(term => term).length) {
						return true;
					}

					return (
						terms.some(term => term && item.answer.toLowerCase().includes(term)) ||
						terms.some(term => term && item.question.toLowerCase().includes(term))
					);
				})
				.filter(item => !category || item.categories.includes(category as FaqCategory)),
		);
	}, [search, category]);

	const handleCategoryClick = (selected: FaqCategory) => {
		setCategory(prev => {
			if (prev === selected) {
				return '';
			}
			return selected;
		});
	};

	const [expandedKey, setExpandedKey] = useState('');
	const handleClick = (faq: Faq) => {
		setExpandedKey(prev => (prev === faq.question ? '' : faq.question));
	};

	return (
		<Main>
			<Helmet>
				<title>{meta.title}</title>
				<meta name="description" content={meta.desc} />
			</Helmet>
			<Header
				variant="light"
				color={colors.white}
				bgColor={colors.transparent}
				isLanding
				searchable
			/>
			<HeaderContainer>
				<TitleText>DigsConnect FAQ</TitleText>
				<BackgroundImage src={`${imgixMsdfUrl}?auto=compress&auto=format&h=700&w=${width}`} />
			</HeaderContainer>
			<Container>
				<Content>
					<Categories>
						{categories.map(c => (
							<CategoryFilter
								onClick={() => handleCategoryClick(c.category)}
								className={c.category === category ? 'selected' : ''}
							>
								{c.label}
							</CategoryFilter>
						))}
					</Categories>
					<SearchContainer>
						<Input
							value={search}
							onChange={value => {
								setSearch(value as string);
							}}
							placeholder="Tell us what you're looking for"
							prefixIcon={<SearchIcon />}
						/>
					</SearchContainer>
					<Divider />
					{faqs.map(faq => (
						<>
							<CollapsibleSection
								header={faq.question}
								expand={faq.question === expandedKey}
								onClick={() => handleClick(faq)}
							>
								<AnswerText dangerouslySetInnerHTML={{ __html: faq.answer }} />
							</CollapsibleSection>
						</>
					))}
				</Content>
			</Container>
			<Footer />
		</Main>
	);
};

const HeaderContainer = styled.div`
	margin-top: -110px;
	width: 100%;
	height: 300px;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	@media (max-width: ${BREAKPOINT_TABLET_PX + 1}px) {
		height: 200px;
	}
`;

const BackgroundImage = styled.img`
	object-fit: cover;
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 0;
`;

const TitleText = styled.h1`
	position: absolute;
	bottom: 60px;
	font-size: 3em;
	font-weight: ${fontWeights.bold};
	color: ${colors.white};
	z-index: 1;

	@media (max-width: ${BREAKPOINT_TABLET_PX + 1}px) {
		font-size: 24px;
		bottom: 40px;
	}
`;

const Categories = styled.div`
	display: flex;
	justify-content: space-evenly;
	margin-bottom: 24px;
	margin-left: -8px;
	margin-right: -8px;
`;

const CategoryFilter = styled.div`
	min-height: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: ${colors.darkBlue};
	border-radius: 16px;
	border: 1px solid ${colors.grey60};
	flex: 1;
	margin-left: 8px;
	margin-right: 8px;
	cursor: pointer;

	&.selected {
		background: ${colors.darkBlue};
		border-color: ${colors.darkBlue};
		color: ${colors.white};
		font-weight: 700;
	}
`;

const Content = styled.div`
	margin: auto;
	max-width: 1200px;
`;

const Container = styled.div`
	margin: 32px 24px;
	background: ${colors.white};
`;

const AnswerText = styled.p`
	color: ${colors.grey60};

	a {
		color: ${colors.pink};
	}

	ul {
		list-style: disc;

		> li {
			list-style: disc;
			margin-left: 24px;
		}
	}
`;

const SearchIcon = styled(SearchSvg)`
	fill: ${colors.darkBlue};
	height: 24px;
	width: 24px;
	min-height: 24px;
	min-width: 24px;
	margin-right: 8px;
`;

const SearchContainer = styled.div`
	margin-bottom: 32px;
`;

export default Faqs;
