export interface Faq {
	question: string;
	answer: string;
	categories: FaqCategory[];
}

export enum FaqCategory {
	Payments = 'Payments',
	Deposits = 'Deposits',
	Leases = 'Leases',
}
